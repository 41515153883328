<template>
  <div :class="'program program--' + status">
    <div class="program__content">
      <h2 class="program__name">{{ nazev_veda }}</h2>
      <span class="program__datum">
        Datum založení:
        {{ datum(datum_zalozeni) }}
      </span>
      <span class="program__status">Stav projektu: {{ nazev_statusu }}</span>
      <span class="program__status">Oblast: {{ oblast_name }}</span>
      <span class="program__status">Program: {{ nazev_veda }}</span>
      <span class="program__value" v-if="cena != null">Finanční hodnota: {{ cena }},-</span>
    </div>

    <div class="program__actions">
      <button
        @click="$emit('upravit')"
        class="program__actions__view program__actions__view--bila"
        v-if="historicky || status == '2' || status == '4'"
      >
        <span>view</span>
      </button>
      <button
        @click="$emit('upravit')"
        class="program__actions__edit"
        v-if="!historicky && (status == '3' || status == '1')"
      >
        <span>edit</span>
      </button>
   
   
  
   
      <button
        class="program__actions__delete"
        @click="$emit('deleteProgramModal')"
        v-if="!historicky && status == '1'  && veda != '1'" >
        <span>delete</span>
      </button>



    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("cs");

export default {
  components: {
  },
  props: {
    id: Number,
    status: Number,
    nazev: String,
    datum_zalozeni: String,
    cena: String,
    veda: Number,
    oblast: Number,
    nazev_statusu: String,
    oblast_name: String,
    nazev_veda: String,
    historicky: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    datum(date) {
      return moment(date).format("LL");
    }
  }
};
</script>